var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"score"},[_c('div',{staticClass:"score-progress"},[_c('progress-bar',{attrs:{"options":_vm.options,"value":Math.floor(_vm.results.percentage),"duration":"2000"}})],1),_vm._v(" "),_c('div',{staticClass:"info"},[_c('h3',{staticClass:"title"},[_vm._v("\n                 "+_vm._s(_vm.trans.get('strings.You got'))+" "+_vm._s(_vm.results.total_correct)+" "+_vm._s(_vm.trans.get('strings.out of'))+"\n                "+_vm._s(_vm.results.total_questions)+" "+_vm._s(_vm.trans.get('strings.correct'))+"\n            ")]),_vm._v(" "),(Math.floor(_vm.results.percentage) == 100)?_c('p',{staticClass:"desc"},[_vm._v("\n                ="+_vm._s(_vm.trans.get('strings.congrats'))+"\n            ")]):_vm._e(),_vm._v(" "),(
                    Math.floor(_vm.results.percentage) > 50 &&
                        Math.floor(_vm.results.percentage) <= 90
                )?_c('p',{staticClass:"desc"},[_vm._v("\n                "+_vm._s(_vm.trans.get('strings.try_again'))+"\n            ")]):_vm._e(),_vm._v(" "),(
                    Math.floor(_vm.results.percentage) > 20 &&
                        Math.floor(_vm.results.percentage) <= 50
                )?_c('p',{staticClass:"desc"},[_vm._v("\n                Not Bad, but you can do it better! Click below to rewatch\n                the video and retake the quiz.\n            ")]):_vm._e(),_vm._v(" "),(Math.floor(_vm.results.percentage) < 20)?_c('p',{staticClass:"desc"},[_vm._v("\n                "+_vm._s(_vm.trans.get('strings.fail'))+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('a',{staticClass:"gold-btn",attrs:{"href":("/lessons/" + (_vm.results.quiz_questions.quiz.lesson_id))}},[_vm._v("\n                    "+_vm._s(_vm.trans.get('strings.Rewatch video'))+"\n                ")]),_vm._v(" "),(Math.floor(_vm.results.percentage) < 100)?_c('a',{staticClass:"gold-btn",staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("\n                    "+_vm._s(_vm.trans.get('strings.Retake quiz'))+"\n                ")]):_vm._e(),_vm._v(" "),(Math.floor(_vm.results.percentage) == 100)?_c('a',{staticClass:"default-btn ml-3",on:{"click":function($event){return _vm.goNext()}}},[_vm._v(_vm._s(_vm.trans.get('strings.Next lesson')))]):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"results"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.trans.get('strings.Your results')))]),_vm._v(" "),_c('div',{staticClass:"result-holder"},_vm._l((_vm.results.quiz_questions
                    .taken_questions),function(result,i){return _c('div',{key:i,staticClass:"result"},[_c('div',{staticClass:"type"},[(
                            _vm.getLabel(
                                result.correct_answers,
                                result.chosen_answers
                            )
                        )?_c('div',{staticClass:"correct"},[_vm._v("\n                        "+_vm._s(_vm.trans.get('strings.correct'))+"\n                    ")]):_c('div',{staticClass:"incorrect"},[_vm._v("\n                        "+_vm._s(_vm.trans.get('strings.incorrect'))+"\n                    ")])]),_vm._v(" "),_c('div',{staticClass:"res"},[_c('div',[(
                               i+1 >= 2
                            )?_c('span',[_vm._v("\n                            "+_vm._s(i + 1)+". "+_vm._s(result.actual_question)+"\n                        ")]):_c('span',[_vm._v("\n                            "+_vm._s(i + 1)+". "+_vm._s(result.actual_question)+"\n                        ")])]),_vm._v(" "),(Array.isArray(result.correct_answers))?_c('span',_vm._l((result.correct_answers),function(correctAnswer,i){return _c('span',{key:i,staticClass:"correct-answer"},[_c('div',{staticClass:"correct"},[_vm._v("\n                                "+_vm._s(correctAnswer.content)+"\n                            ")])])}),0):_c('span',{staticClass:"correct-answer"},[_c('span',{staticClass:"correct"},[_vm._v("\n                            "+_vm._s(result.correct_answers)+"\n                        ")])]),_vm._v(" "),_vm._l((result.chosen_answers),function(answer,i){return _c('span',{key:i,staticClass:"correct-answer"},[(!answer.correct)?_c('div',{staticClass:"incorrect",staticStyle:{"color":"red"}},[_vm._v("\n                            "+_vm._s(answer.given_answer.content)+"\n                        ")]):_vm._e()])})],2)])}),0)]),_vm._v(" "),_c('div',{staticClass:"d-flex next-btn"}),_vm._v(" "),_c('div',{staticClass:"d-flex align-center justify-space-between lesson-navigation"}),_vm._v(" "),(_vm.module.lessons.length)?_c('slider-component',{attrs:{"lessons":_vm.module.lessons}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }